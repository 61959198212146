<template>
  <el-card v-loading="loading" style="min-height: 45rem">
    <el-row :gutter="40">
      <el-col :span="12">
        <el-input
            type="textarea"
            :rows="12"
            placeholder="请输入内容"
            v-model="value">
        </el-input>
      </el-col>
      <el-col :span="6">
        <div class="y-desc">填写淘宝商品链接,每行一条</div>
        <div class="y-desc">导入的等待时间随商品链接数量 及每个商品规格的数量增加，多规格商品建议单独导入</div>
        <div style="margin-top: 2rem">
          <el-button @click="submit" style="width: 100%">开始导入</el-button>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "import",
  data(){
    return{
      value:"",
      loading:false,
    }
  },
  methods:{
    submit(){
      this.loading = true;
      this.$u.api.shop.goods.importTaoGoods({urls:this.value}).then(res=>{
        this.loading = false;
        this.$message.success(`成功导入${res.ok_num}个商品;${res.err_tips}`);
      }).catch(()=>this.loading=false)
    },
  }
}
</script>

<style scoped>

</style>